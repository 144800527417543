import * as API from './baseConst';

export const catalogAPI = {
  getDefectsCatalog: function (id) {
    if (id) return fetch(`${API.BASE_URL}/defects_guide/${id}`);
    return fetch(`${API.BASE_URL}/defects_guide/`);
  },
  createDefectItem: function (jsonObject) {
    return fetch(`${API.BASE_URL}/defects_guide/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonObject),
    });
  },
  updateSurface: function (data) {
    return fetch(`${API.BASE_URL}/surface_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  getSurfaceByID: function (id) {
    if (id) return fetch(`${API.BASE_URL}/surface_guide/${id}`);
  },
  getAllSurface: function(){
    return fetch(`${API.BASE_URL}/surface_guide`);
  },
  getSurfaceDefectsByID: function (id) {
    if (id) return fetch(`${API.BASE_URL}/surface_guide/${id}`);
  },
  updateDefectsForSurface: function (data) {
    return fetch(`${API.BASE_URL}/defects_guide/update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник видов экспертиз
  //==============================
  getExpertisesTypeCatalog: function () {
    return fetch(`${API.BASE_URL}/exp_type_guide`);
  },
  // Дабавление нового вида экспертизы
  addNewExpertiseType: function (data) {
    return fetch(`${API.BASE_URL}/exp_type_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  // Обновить запись в справочнике видов экспертиз
  updateExpertiseTypeItem: function (data) {
    return fetch(`${API.BASE_URL}/exp_type_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник подвидов экспертиз
  //==============================
  //Получить справочник подвидов экспертиз
  getSubTypeExpertiseCatalog: function () {
    return fetch(`${API.BASE_URL}/exp_subtype_guide`);
  },
  //Добавить новый подвид в каталог
  addNewSubTypeExpertiseItem: function (data) {
    return fetch(`${API.BASE_URL}/exp_subtype_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateSubTypeExpertiseItem: function (data) {
    return fetch(`${API.BASE_URL}/exp_subtype_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Получить все подтипы экспертизы, по ID типа экспертизы
   */
  getAllSubtypesById: function (id) {
    return fetch(`${API.BASE_URL}/exp_subtype_guide/by_exp_type_guide/${id}`);
  },
  //==============================
  // Справочник регионов
  //==============================
  //Получить справочник регионов
  getRegionsCatalog: function () {
    return fetch(`${API.BASE_URL}/region_guide`);
  },
  // Добавление нового региона в справочник
  addNewRegion: function (data) {
    return fetch(`${API.BASE_URL}/region_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateRegionItem: function (data) {
    return fetch(`${API.BASE_URL}/region_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник организаций
  //==============================
  //Получить справочник организаций
  getOrganizationCatalog: function () {
    return fetch(`${API.BASE_URL}/org_guide`);
  },
  //Добавление новой организации
  addNewOrganization: function (data) {
    return fetch(`${API.BASE_URL}/org_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Обновить запись в справочнике организация
  updateOrganizationItem: function (data) {
    return fetch(`${API.BASE_URL}/org_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Получить регионы организации
  getOrganizationRegion: function (id) {
    return fetch(`${API.BASE_URL}/org_reg_guide2/by_id_org/${id}`);
  },
  //Привязать регион к организации
  addRegionForOrganization: function (data) {
    return fetch(`${API.BASE_URL}/org_reg_guide2`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateOrganizationRegion: function (data) {
    return fetch(`${API.BASE_URL}/org_reg_guide2`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  getAllInspectionObject: function () {
    return fetch(`${API.BASE_URL}/insp_obj_guide`);
  },
  createInspectionObject: function (data) {
    return fetch(`${API.BASE_URL}/insp_obj_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateInspectionObject: function (data) {
    return fetch(`${API.BASE_URL}/insp_obj_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },

  getAllCourts: function () {
    return fetch(`${API.BASE_URL}/court_guide`);
  },
  createCourt: function (data) {
    return fetch(`${API.BASE_URL}/court_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateCourt: function (data) {
    return fetch(`${API.BASE_URL}/court_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Получить список типов городов
  getLocality: function () {
    return fetch(`${API.BASE_URL}/guides_fixed/locality`);
  },
  /**
   * Получить список судей
   */
  getAllJudes: function () {
    return fetch(`${API.BASE_URL}/judge_guide`);
  },
  /**
   * Добавить судью
   */
  addNewJudges: function (data) {
    return fetch(`${API.BASE_URL}/judge_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Обновить запись в каталоге судей
   */
  updateJudgeItem: function (data) {
    return fetch(`${API.BASE_URL}/judge_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Получить список контактных лиц
   */
  getAllContacts: function () {
    return fetch(`${API.BASE_URL}/contact_guide`);
  },
  /**
   * Добавить новый контакт
   */
  createContact: function (data) {
    return fetch(`${API.BASE_URL}/contact_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Обновить запись контакта
   */
  updateContact: function (data) {
    return fetch(`${API.BASE_URL}/contact_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Фиксированные справочники
  /**
   * Получить список регионов
   */
  getRegions(){
    return fetch(`${API.BASE_URL}/guides_fixed/regionRus`);
  },
  //==============================
  //Справочник материалов
  //=============================
  /**
   * Получить список материалов
   */
  getMaterials(){
    return fetch(`${API.BASE_URL}/material_guide`);
  },
  /**
   * Добавить материал в справочник
   */
  createMaterial(data){
    return fetch(`${API.BASE_URL}/material_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Изменить материал
   */
  updateMaterial(data){
    return fetch(`${API.BASE_URL}/material_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Получить массив возможных типов обьектов осмотра
   */
  getAllStudyObjectsType(){
    return fetch(`${API.BASE_URL}/guides_fixed/study_obj_type`);
  },
  /**
   * Получить список Типов улиц
   */
  getTypeStreets(){
    return fetch(`${API.BASE_URL}/guides_fixed/street_type`);
  }

};