<template>
    <v-card>
        <v-dialog v-model="dialogEdit" width="500">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Редактирование</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form v-model="isValidEditFrom" ref="editForm">
                        <v-text-field label="Наименование объекта" required v-model="editedItem.name"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']" outlined>
                        </v-text-field>
                        <v-checkbox hide-details name="requiredCause" v-model="editedItem.isDefaultInExpertise"
                            :label="`Создавать по умолчанию`"></v-checkbox>
                        <v-checkbox hide-details name="requiredCause" v-model="editedItem.isLinkDefects"
                            :label="`Можно привязовать недостатки`"></v-checkbox>
                            <v-checkbox hide-details name="requiredCause" v-model="editedItem.isRoom"
                                :label="`Является помещением`"></v-checkbox>
                                <v-checkbox hide-details name="requiredCause" v-model="editedItem.archive"
                                    :label="`Архивный пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogEdit = false">
                        Отмена
                    </v-btn>
                    <v-btn color="primary" text @click="updateExpertiseType">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-title>
            Справочник объектов осмотра
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="col-auto">
                    <v-btn color="primary" @click="openNewDialog">
                        Добавить
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr v-if="inspections.length == 0">
                                    <td>Справочник пуст</td>
                                </tr>
                                <tr v-for="(item, i) in inspections" :key="item.id">
                                    <td>{{ item.name }}</td>
                                    <td style="width: 50px;">
                                        <v-btn icon @click="openEditDialog(item, i)">
                                            <v-icon>mdi-file-edit-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
export default {
    components: {
    },
    data: () => ({
        inspections: [],
        regionName: '',
        regionCode: '',
        isValidForm: true,
        isValidEditFrom: true,
        dialogEdit: false,
        editedItem: {
            id: null,
            isDefaultInExpertise: false,
            isLinkDefects: false,
            isRoom: '',
            name: '',
            archive:false
        },
        editedItemIndex: -1
    }),
    methods: {
        openNewDialog(){
            this.editedItemIndex = -1;
            this.dialogEdit = true;
        },
        openEditDialog(item, i) {
            this.editedItem = { ...item };
            this.editedItemIndex = i
            this.dialogEdit = true;
        },
        async updateExpertiseType() {
            this.$refs.editForm.validate();
            if (!this.isValidEditFrom) return;
            if(this.editedItemIndex<0){
                let obj = {
                    name: this.editedItem.name,
                    isDefaultInExpertise: this.editedItem.isDefaultInExpertise,
                    isLinkDefects: this.editedItem.isLinkDefects,
                    isRoom: this.editedItem.isRoom,
                }
                try{
                    let created = await catalogAPI.createInspectionObject(obj);
                    if(created.ok){
                        let json = await created.json();
                        this.inspections.push(json);
                        this.$emit('msg:success', 'Запись успешно добавлена');
                    } else{
                        this.$emit('msg:error', 'Что-то пошло не так');
                    }
                }catch(e){
                    console.log(e);
                    this.$emit('msg:error', 'Что-то пошло не так');
                }
                this.dialogEdit = false;
                return;
            }
            try {
                let obj = {
                    name: this.editedItem.name,
                    isDefaultInExpertise: this.editedItem.isDefaultInExpertise,
                    isLinkDefects: this.editedItem.isLinkDefects,
                    isRoom: this.editedItem.isRoom,
                    id: this.inspections[this.editedItemIndex].id,
                    archive: this.editedItem.archive,
                    number:1
                }
                let response = await catalogAPI.updateInspectionObject(obj)
                if (!response.ok) {
                    let text = await response.text();
                    this.$emit('msg:error', text);
                    console.warn(text);
                    return;
                }
                let data = await response.json();
                console.log(data);
                Object.assign(this.inspections[this.editedItemIndex], data)
                this.$emit('msg:success', 'Запись успешно обновлена');
            } catch (e) {
                this.$emit('msg:error', e.message);
            }

        },
        async fetchCatalog() {
            try {
                let response = await catalogAPI.getAllInspectionObject();
                if (!response.ok) {
                    let textError = await response.text();
                    this.$emit('show:error', textError);
                    console.log(textError);
                    return;
                }
                let data = await response.json();
                this.inspections = data.inspObjGuideList;
            } catch (e) {
                this.$emit('show:error', e.message);
                console.log(e.message);
            }
        },
    },
    mounted() {
        this.fetchCatalog();
    }
}
</script>