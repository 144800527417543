<template>
  <div>
        <div class="text-right pb-4" v-if="this.selectedItem == null">
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-4" :loading="exportLoading" @click="exportDocx">
            <v-icon left>
                mdi-download
            </v-icon>
            Экспорт в word
            </v-btn>
        </div>
        <!-- ОБЩАЯ ТАБЛИЦА ПОМЕЩЕНИЙ -->
        <v-simple-table class="border-table" v-if="this.selectedItem==null">
            <template v-slot:default>
            <thead>
                <tr >
                    <th class="text-center">
                        Наименование
                    </th>
                    <th class="text-center">
                        Площадь из<br>договора ДДУ (кв.м)
                    </th>
                    <th class="text-center">
                        Общая площадь (кв.м)
                    </th>
                    <th class="text-center">
                        Высота<br>потолков (мм)
                    </th>
                    <th class="text-center">
                        Площадь<br>дверных проемов (кв.м)
                    </th>
                    <th class="text-center">
                        Площадь<br>оконных проемов (кв.м)
                    </th>
                    <th class="text-center">
                        Площадь<br>проемов (кв.м)
                    </th>
                    <th class="text-center">
                        Периметр (мм)
                    </th>
                    <th class="text-center">
                        Периметр стен<br> без проемов (мм)
                    </th>
                    <th class="text-center">
                        Площадь стен<br> без проемов (кв.м)
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                @click="handleClick(item,i)"
                :class="{ blue: selectedIndx==i }"
                v-for="(item,i) in rooms"
                :key="item.id"
                >
                <td>{{ item.inspObjGuide.name }}</td>
                <td>{{  }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                    <td></td>
                <td></td>
                <td></td>
                <td></td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
      <v-data-table
      v-if="false"
        :disable-pagination="true"
        hide-default-footer
        :headers="headers"
        :items="rooms"
        v-model="selectedRows"
        class="elevation-0"
        @click:row="handleClick"
      >
        <template v-slot:item.name="{ item }">
            {{ item.inspObjGuide.name }}
        </template>
      </v-data-table>
        <!-- ДОБАВЛЕНИЕ/РЕДАКТИРОВАНИЕ ПРОЕМОВ -->
        <v-card outlined v-if="showForm" class="mt-4">
            <v-card-title v-if="modeAdd">{{ roomName }}. Добавление проема</v-card-title>
            <v-card-title v-else>{{ roomName }}. Редактирование проема</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                            <v-autocomplete
                              label="Проем"
                              outlined
                              dense
                              item-text="name"
                              item-value="id"
                              :items="apertures"
                              v-model="apertureID">
                            </v-autocomplete>
                    </v-col>
                </v-row>
                        <v-autocomplete
                            label="Материал"
                            outlined
                            dense
                            item-text="name"
                            item-value="id"
                            :items="materials"
                            v-model="materialID">
                        </v-autocomplete>
                        <v-text-field
                        outlined
                        dense
                          v-model="width"
                          label="Ширина, мм"
                        ></v-text-field>
                            <v-text-field
                            outlined
                            dense
                              v-model="height"
                              label="Высота, мм"
                            ></v-text-field>
            </v-card-text>
            <v-card-actions>
                        <v-btn
                        text
                        color="primary"
                        @click="showForm = false"
                        >
                        <v-icon left>
                            mdi-arrow-left
                        </v-icon>
                        Назад
                        </v-btn>
                <v-spacer></v-spacer>
                            <v-btn
                            @click="createOpening()"
                            color="primary"
                            >
                            {{modeAdd?"Добавить":"Сохранить"}}
                            </v-btn>
            </v-card-actions>
        </v-card>
        <v-card outlined class="mt-4" v-if="!showForm && selectedItem!=null">
            <v-card-title>Список проемов ( {{ roomName }} )</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-btn
                        text
                        color="primary"
                        @click="backToCommonTable()"
                        >
                        <v-icon left>
                            mdi-arrow-left
                        </v-icon>
                        Назад
                        </v-btn>
                        <v-btn
                        v-if="selectedIndx !=null"
                        @click="addNewOpen()"
                        color="primary"
                        >
                        Добавить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-simple-table class="border-table mt-4">
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            Наименование
                        </th>
                        <th class="text-left">
                            Материал
                        </th>
                        <th class="text-left">
                            Ширина (мм)
                        </th>
                        <th class="text-left">
                            Высота (мм)
                        </th>
                            <th class="text-left">
                                Площадь (кв.м)
                            </th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,i) in openingList"
                        :key="item.id"
                        >
                        <td>{{ getNameSurface(item.idSurfaceGuide) }}</td>
                        <td>{{ getNameMaterial(item.idMaterialGuide) }}</td>
                        <td>{{ item.width }}</td>
                        <td>{{ item.height }}</td>
                        <td>{{ item.area }}</td>
                        <td style="width:50px">
                            <v-btn icon @click="openEditDialog(item, i)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </td>
                        </tr>
                        <tr v-if="openingList.length==0">
                            <td colspan="5" class="text-center">Нет добавленных проемов</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
  </div>
</template>

<script>
import { getAllInspObject, getAllDefectSurfaces, createOpening, getOpeningByIDInspection, updateOpening, generateTableSize  } from "../../api/expertiseApi";
import { catalogAPI } from "../../api/catalogsApi";
export default {
    components: {
    },
    data() {
        return {
            selectedIndx:null,
            exportLoading:false,
            showForm: false,
            selectedRows:[],
            room:null,
            materialID: '',
            materials: [],
            roomName: 'Наименование помещения',
            apertures: [],
            apertureID: null,
            width: '',
            height: '',
            openingList:[],
            selectedItem:null,
            headers:[{text:'Помещение',value:'name'},{text:`Площадь проемов`,value:"square"}, { text: "Периметр", value: "perimetr" }],
            rooms: [],
            editedItem:null,
            editedIndex:null,
            modeAdd:true,
        }
    },
    mounted () {
        this.initState();
    },
    methods: {
        async exportDocx(){
            this.exportLoading = true;
            let response = await generateTableSize(this.$route.params.id);
            if (response.ok) {
                let fileName = 'Документ.docx';
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) {
                        fileName = fileNameMatch[1];
                    }
                }
                let blob = await response.blob();
                const file = new Blob([blob]);
                const fileUrl = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
                this.exportLoading = false;
            } else {
                this.exportLoading = false;
            }
        },
        addNewOpen(){
            this.modeAdd = true;
            this.showForm = true;
            this.height = '';
            this.width = '';
            this.apertureID = null;
            this.materialID = null;
        },
        openEditDialog(item,i){
            this.modeAdd = false;
            this.showForm = true;
            this.editedItem = item;
            this.editedIndex = i;
            this.materialID = item.idMaterialGuide;
            this.height = item.height;
            this.width = item.width;
            this.apertureID = item.idSurfaceGuide;

        },
        getNameSurface(id){
            let find = this.apertures.find(item=>item.id==id);
            if(find) return find.name;
        },
        getNameMaterial(idMaterial){
            let find = this.materials.find(item=>item.id==idMaterial);
            if(find) return find.name;
            else return '-';
        },
        handleClick(item,i){
            this.selectedIndx = i;
            this.showForm = false;
            this.selectedItem = item;
            this.openingList = this.selectedItem.openingList;
            this.roomName = item.inspObjGuide.name
        },
        backToCommonTable(){
            this.selectedIndx = -1;
            this.selectedItem = null;
        },
        async initState() {
            let roomsResponse = await getAllInspObject(this.$route.params.id);
            if(roomsResponse.ok){
                let items = await roomsResponse.json();
                this.rooms = items.inspObjList.filter(obj=>obj.inspObjGuide.isRoom);
                for(let i=0;i<this.rooms.length;i++){
                    let openingResponse = await getOpeningByIDInspection(this.rooms[i].inspObjGuide.id);
                    if(openingResponse.ok){
                        let json = await openingResponse.json();
                        this.rooms[i]['openingList'] = [];
                        this.rooms[i]['openingList'] = json.openingList;
                    }
                }
            }
            try {
                let surfacesResponse = await getAllDefectSurfaces();
                let surfaces = await surfacesResponse.json();
                this.apertures = surfaces.surfaceGuideList.filter(item => (item.isOpening && !item.archive));
                //TODO Выводить сообщения
                let m = await catalogAPI.getMaterials();
                if (m.ok) {
                    let materials = await m.json();
                    this.materials = materials.materialGuideList;
                }
            } catch (e) {
                //TODO Выводить сообщение об ошибки
                console.log(e.message);
            }
        },
        async createOpening() {
            try {
                if(this.modeAdd){
                    let response = await createOpening({
                        idInspObj: this.selectedItem.id,
                        idSurfaceGuide: this.apertureID,
                        idMaterialGuide: this.materialID,
                        width: this.width,
                        height: this.height,
                    });
                    if (response.ok) {
                        let data = await response.json();
                        this.selectedItem.openingList.push(data);
                        this.showForm = false;
                    }
                } else {
                    let response = await updateOpening({
                        id:this.editedItem.id,
                        idInspObj: this.selectedItem.id,
                        idSurfaceGuide: this.apertureID,
                        idMaterialGuide: this.materialID,
                        width: this.width,
                        height: this.height,
                    })
                    if(response.ok){
                        let json = await response.json();
                        this.editedItem.idSurfaceGuide = this.apertureID;
                        this.editedItem.idMaterialGuide = this.materialID;
                        this.editedItem.width = this.width;
                        this.editedItem.height = this.height;
                        this.editedItem.area = json.area;
                        console.log(json)
                        this.showForm = false;
                    }
                }

            } catch (e) {
                console.log(e.message);
            }
        }
    },
}
</script>

<style scoped>
.border-table {
    border-collapse: collapse;
    /* убираем пустые промежутки между ячейками */
    border: 1px solid grey;
    /* устанавливаем для таблицы внешнюю границу серого цвета толщиной 1px */
}

.border-table td,
.border-table th {
    border: 1px solid grey;
}
.border-table th {
    background-color: rgb(191, 191, 191);
}
.border-table td:first-child{
    background-color: rgb(231, 231, 231);
}
</style>