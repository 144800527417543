<template>
  <div>
    <!-- Карточка редактирования/добавления -->
    <v-row v-if="showForm">
        <v-col lg="8" offset-lg="2" sm="12">
            <v-card>
                <v-card-title>{{ cardTitleEdit }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field outlined dense label="Наименование материала" required v-model="editedItem.name"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-checkbox v-if="!modeAdd" hide-details name="requiredCause" v-model="editedItem.archive"
                            :label="`Архивый пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    text
                    color="primary"
                    @click="showForm = false"
                    >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Назад
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    v-if="modeAdd"
                    color="primary"
                    @click="addNew"
                    >
                    Добавить
                    </v-btn>
                    <v-btn
                    v-else
                    @click="saveChange"
                    color="primary"
                    >
                    Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>


    <v-row v-if="!showForm">
        <v-col lg="8" offset-lg="2" sm="12">
            <v-card>
                <v-card-title>Справочник материалов</v-card-title>
                <v-card-text>
                    <v-btn @click="showFormNew"
                    color="primary"
                    >
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    Добавить
                    </v-btn>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Наименование материала
                                </th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(item,i) in materials"
                            :key="item.id"
                            >
                            <td>{{ item.name }}</td>
                            <td style="width:50px">
                                <v-btn icon @click="openEditDialog(item, i)">
                                    <v-icon>mdi-file-edit-outline</v-icon>
                                </v-btn>
                            </td>
                            </tr>
                            <tr v-if="materials.length==0" class="text-center">
                                <td colspan="5">Справочник пуст</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

  </div>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
export default {
    data() {
        return {
            cardTitleEdit: '',
            itemDefault:{
                name: '',
            },
            editedItem:{
                name:'',
            },
            modeAdd:true,
            showForm:false,
            materials:[],
            indexEdited:-1
        }
    },
    methods: {
        showFormNew() {
            Object.assign(this.editedItem,this.itemDefault);
            this.showForm = true;
            this.modeAdd = true;
            this.cardTitleEdit = 'Добавление материала';
        },
        async addNew(){
            try{
                let response = await catalogAPI.createMaterial(this.editedItem);
                if(response.ok){
                    let data = await response.json();
                    this.materials.push(data);
                    this.showForm = false;
                    this.$emit('msg:success','Материал добавлен');
                } else {
                    this.showForm = false;
                    let text = await response.text();
                    this.$emit('msg:error', text);
                }
            }catch(e){
                this.showForm = false;
                console.log(e.message);
                this.$emit('msg:error', 'Что-то пошло не так');
            }
        },
        openEditDialog(item,i){
            this.cardTitleEdit = 'Редактирование материала';
            this.modeAdd = false;
            this.showForm = true;
            Object.assign(this.editedItem,item);
            this.indexEdited = i;
        },
        async saveChange(){
            try {
                let response = await catalogAPI.updateMaterial(this.editedItem);
                if(response.ok){
                    let data = await response.json();
                    Object.assign(this.materials[this.indexEdited],data);
                    this.$emit('msg:success', 'Запись обновлена');
                    this.showForm = false;
                } else {
                    let text = await response.text();
                    this.$emit('msg:error', text);
                    this.showForm = false;
                }

            } catch (e) {
                this.showForm = false;
                console.log(e.message);
                this.$emit('msg:error', 'Что-то пошло не так');
            }
        },
    },
    async mounted () {
        try {
            let catalog = await catalogAPI.getMaterials();
            if (catalog.ok) {
                let data = await catalog.json();
                this.materials = data.materialGuideList;
            } else {
                this.$emit('msg:error', 'Не удалось получить список материалов');
            }

        }catch (error) {
            console.log(error.message);
            this.$emit('msg:error','Что-то пошло не так');
        }
    }

}
</script>

<style>

</style>