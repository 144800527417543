<template>
    <v-card>
            <v-dialog
              v-model="dialogEditSub"
              width="500"
            >
              <v-card>
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-toolbar-title>Подвид экспертизы</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                      icon
                      dark
                      @click="dialogEditSub = false"
                    >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form v-model="isValidEditFromSub" ref="editFormSub">
                        <v-text-field
                        label="Наименование"
                        outlined
                        required
                        v-model="editedItemSub.name"
                        :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                        >
                        </v-text-field>
                        <v-checkbox
                        hide-details
                        name="requiredCause"
                        v-model="editedItemSub.archive"
                        :label="`Архивый пункт`"
                        ></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="dialogEditSub = false"
                    >
                    Отмена
                    </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="submitSubtypeForm"
                  >
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

        <v-dialog
          v-model="dialogEdit"
          width="500"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-toolbar-title>Редактирование</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  dark
                  @click="dialogEdit = false"
                >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">
                <v-form v-model="isValidEditFrom" ref="editForm">
                    <v-text-field
                    label="Код экспертизы"
                    required
                    v-model="editedItem.code"
                    :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                    >
                    </v-text-field>
                    <v-text-field
                    label="Наименование экспертизы"
                    required
                    v-model="editedItem.name"
                    :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                    >
                    </v-text-field>
                    <v-checkbox
                    hide-details
                    name="requiredCause"
                    v-model="editedItem.archive"
                    :label="`Архивый пункт`"
                    ></v-checkbox>
                </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                text
                @click="dialogEdit = false"
                >
                Отмена
                </v-btn>
              <v-btn
                color="primary"
                text
                @click="updateExpertiseType"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
            Справочник видов экспертиз
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                    <v-form v-model="isValidForm" ref="newForm">
                        <v-row>
                            <v-col>
                                <v-text-field
                                label="Код экспертизы"
                                dense
                                required
                                outlined
                                v-model="expertiseCode"
                                :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                outlined
                                dense
                                label="Наименование экспертизы"
                                required
                                v-model="expertiseTypeName"
                                :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col class="col-auto">
                    <v-btn
                    color="primary"
                    @click="submitNewSurface"
                    >
                    Добавить
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <!-- <v-simple-table v-show="true">
                        <template v-slot:default>
                            <tbody>
                                <tr v-if="expertiseTypes.length==0">
                                    <td>Справочник пуст</td>
                                </tr>
                                <tr
                                v-for="(item,i) in expertiseTypes"
                                :key="item.id"
                                >
                                <td>{{ item.code }}</td>
                                <td>{{ item.name }}</td>
                                <td style="width: 50px;">
                                    <v-btn icon @click="openEditDialog(item,i)">
                                        <v-icon>mdi-file-edit-outline</v-icon>
                                    </v-btn>
                                </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table> -->
                    <!-- <v-simple-table dense >
                        <template v-slot:default>
                            <tbody>
                                <tr v-if="fullCatalog.length == 0">
                                    <td>Справочник пуст</td>
                                </tr>
                                <template v-for="(item, i) in expertiseTypes" >
                                    <tr :key="item.id" class="grey lighten-2">
                                    <td class="text-h5 ">{{ item.code }}</td>
                                    <td class="text-h5 ">{{ item.name }}</td>
                                    <td style="width: 50px;">
                                        <v-btn icon @click="openEditDialog(item, i)">
                                            <v-icon>mdi-edit</v-icon>
                                        </v-btn>
                                    </td>
                                    </tr>
                                    <tr :key="item.id+'-sub'" class="grey lighten-3 text-center subtitle-2">
                                        <td colspan="3">Подвиды экспертизы
                                                  <v-icon color="primary"
                                                  >
                                                    mdi-plus
                                                  </v-icon>
                                        </td>
                                    </tr>
                                    <template >
                                        <tr :key="item.id + '-subTypesNone'" v-if="item.subtypes.length==0" class="text-center text--secondary ">
                                            <td colspan="3">Подвиды отсутствуют</td>
                                        </tr>
                                        <tr v-else :key="subtype.id + '-subTypes'" v-for="(subtype, j) in item.subtypes">
                                            <td>{{ subtype.name }}</td>
                                            <td>{{ j }}</td>
                                        </tr>
                                    </template>
                                </template>

                            </tbody>
                        </template>
                    </v-simple-table> -->
                </v-col>
            </v-row>
            <div>
                <v-expansion-panels
                multiple
                >
                <v-expansion-panel
                    v-for="(item, i) in fullCatalog"
                    :key="i"
                >
                    <v-expansion-panel-header class="grey lighten-3">
                        {{item.code}} {{ item.name }}
                        <div>
                            <v-btn icon @click.stop="openEditDialog(item, i)">
                                <v-icon>mdi-file-edit-outline</v-icon>
                            </v-btn>
                        </div>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content >
                            <div class="text-center text--secondary mt-4">
                                <v-btn  color="primary" outlined small @click.stop="openAddDialogSub(item, i)">
                                    <v-icon>mdi-plus</v-icon>
                                    Добавить
                                </v-btn>
                            </div>
                        <v-simple-table dense class="mt-4">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="(subtype) in item.subtypes" :key="'subtype-'+subtype.id">
                                        <td>{{ subtype.name }}</td>
                                        <td style="width:50px">
                                            <v-btn icon @click.stop="openEditSubtypeDialog(subtype, i)">
                                                <v-icon>mdi-file-edit-outline</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
export default {
    components:{
    },
    data:()=>({
        expertiseTypes:[],
        expertiseTypeName:'',
        expertiseCode:'',
        isValidForm:true,
        isValidEditFrom:true,
        dialogEdit:false,
        editedItem:{
            id:null,
            number:null,
            archive:false,
            code:'',
            name:''
        },
        editedItemIndex:-1,
        fullCatalog:[],
        dialogEditSub:false,
        editedItemSub:{
            name:'',
            archive:false
        },
        isValidEditFromSub:true,
        modeAddSubtype:true,
        indexType:-1
    }),
    methods:{
        openEditSubtypeDialog(item,i){
            Object.assign(this.editedItemSub,item);
            this.indexType = i;
            this.modeAddSubtype = false;
            this.dialogEditSub = true;
            console.log(item,i)
        },
        openAddDialogSub(item, i){
            if(this.$refs["editFormSub"]){
                this.$refs["editFormSub"].resetValidation();
            }
            console.log(item, i)
            this.editedItemSub= {
                    name: '',
                    archive: false
            },
            this.indexType = i
            this.modeAddSubtype = true;
            this.dialogEditSub = true;
        },
        async submitNewSurface() {
            this.$refs.newForm.validate();
            if (!this.isValidForm) return;
            try {
                // let formData = new FormData();
                // formData.append('name',this.surfaceName)
                let json = {name:this.expertiseTypeName, code : this.expertiseCode};
                let response = await catalogAPI.addNewExpertiseType(json);
                if (response.ok) {
                    let data = await response.json();
                    this.expertiseCode = '';
                    this.expertiseTypeName = '';
                    this.expertiseTypes.push(data);
                    this.$emit('msg:success', "Операция выполнена успешно");
                    this.$emit('upd:type', this.expertiseTypes);
                    this.$refs.newForm.resetValidation()
                }
            } catch(e) {
                this.$emit('msg:error',e.message);
                console.log('error',e)
            }
        },
        openEditDialog(item,i){
            this.editedItem = {...item};
            this.editedItemIndex = i
            this.dialogEdit = true;
        },
        async updateExpertiseType(){
            this.$refs.editForm.validate();
            if (!this.isValidEditFrom) return;
            this.dialogEdit = false;
            try{
                let response = await catalogAPI.updateExpertiseTypeItem(this.editedItem)
                if(!response.ok){
                    let text = await response.text();
                    this.$emit('msg:error',text);
                    console.warn(text);
                    return;
                }
                let data = await response.json();
                console.log(data);
                Object.assign(this.expertiseTypes[this.editedItemIndex],data)
                this.$emit('upd:type', this.expertiseTypes);
                this.$emit('msg:success','Запись успешно обновлена');
            } catch(e){
                this.$emit('msg:error',e.message);
            }

        },
        async fetchCatalog(){
            try{
                let response = await catalogAPI.getExpertisesTypeCatalog();
                if(!response.ok){
                    let textError = await response.text();
                    this.$emit('show:error', textError);
                    console.log(textError);
                    return;
                }
                let data = await response.json();
                console.log(data,'fetchCatalog');
                this.expertiseTypes = data.expTypeGuideList;
                this.createFullCatalog(data.expTypeGuideList);
                this.fullCatalog = data.expTypeGuideList;
                this.$emit('upd:type', data.expTypeGuideList);
            }catch(e){
                this.$emit('show:error',e.message);
                console.log(e.message);
            }
        },
        createFullCatalog(expTypes){
            for (let i = 0; i < expTypes.length; i++) {
                const item = expTypes[i];
                item['subtypes'] = [];
                this.fullCatalog.push(item)
                // console.log(item)
            }
            this.getSubtypesCatalog();
        },
        async getSubtypesCatalog(){
            try{
                let response = await catalogAPI.getSubTypeExpertiseCatalog();
                if(response.ok){
                    let json = await response.json();
                    console.log(json.expSubtypeGuideList);
                    this.fillFullCatalog(json.expSubtypeGuideList)
                    console.log(this.fullCatalog,'fullcatalog');
                }
            }catch(e){
                console.warn('Что-то пошло не так');
            }
        },
        fillFullCatalog(itemsSubtype){
            for(let i=0;i<itemsSubtype.length;i++){
                let subtype = itemsSubtype[i];
                // let find = this.fullCatalog.find(item=>item.id==subtype.idExpTypeGuide)
                let find = this.fullCatalog.findIndex(item => item.id == subtype.idExpTypeGuide)
                // if(find) find.subtypes.push(subtype)
                if (find>=0){
                    this.fullCatalog[find].subtypes.push({ ...subtype })
                }
            }
        },
        async submitSubtypeForm(){
            if(this.modeAddSubtype){
                let obj = {
                    name:this.editedItemSub.name,
                    idExpTypeGuide: this.fullCatalog[this.indexType].id
                }
                let response = await catalogAPI.addNewSubTypeExpertiseItem(obj);
                if(response.ok){
                    let data = await response.json();
                    console.log(data)
                    this.fullCatalog[this.indexType].subtypes.push(data);
                    this.dialogEditSub = false;
                    this.$emit('msg:success', 'Запись успешно добавлена');
                }
            } else {
                try{
                    let response = await catalogAPI.updateSubTypeExpertiseItem(this.editedItemSub);
                    if (response.ok) {
                        let json = await response.json();
                        let type = this.fullCatalog[this.indexType];
                        console.log(json);
                        for(let i=0;i<type.subtypes.length;i++){
                            if(type.subtypes[i].id == json.id){
                                Object.assign(type.subtypes[i],json);
                                this.$emit('msg:success', 'Запись успешно обновлена');
                                this.dialogEditSub = false;
                                return;
                            }
                        }

                    } else {
                        this.dialogEditSub = false;
                        this.$emit('msg:error', 'Не удалось выполнить запрос');
                    }
                }catch(e){
                    this.dialogEditSub = false;
                    console.log(e.message);
                    this.$emit('msg:error', 'Что-то пошло не так');
                }

            }
        },

    },
    mounted(){
        this.fetchCatalog();
    },
    computed:{
        surfaces(){
            return this.$store.getters.getDefectSurfaces
        }
    }

}
</script>