<template>
     <div>
      <!-- Подтверждение удаления вопроса -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Удалить вопрос?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Отмена</v-btn>
                <v-btn color="red darken-1" text @click="deleteItemConfirm">Удалить</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-dialog
          v-model="dialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="openAdd"
            >
          <v-icon
            left
            dark
          >
            mdi-plus
          </v-icon>
              Добавить
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5" v-if="editedIndex<0">
              Новый вопрос
            </v-card-title>
                <v-card-title class="text-h5" v-if="editedIndex >= 0">
                    Редактирование
                </v-card-title>

            <v-card-text class="pt-6">
              <v-row>
                <v-col>
            <v-textarea
              outlined
              name="input-7-4"
              label="Введите текст вопроса"
              v-model="val"
            ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="dialog = false"
                >
                  Отмена
                </v-btn>
              <v-btn
                color="primary"
                text
                @click="submit"
                v-if="editedIndex < 0"
              >
                Добавить
              </v-btn>
                  <v-btn
                    v-if="editedIndex>=0"
                    color="primary"
                    text
                    @click="submitSave"
                  >
                    Сохранить
                  </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card outlined class="mt-4" v-if="questions.length==0">
          <v-card-text class="text-center">
            Вопросы отсутствуют
          </v-card-text>
        </v-card>
        <v-card class="mt-4" outlined v-for="(item,i) in questions" :key="i"

        >
        <v-card-title>Вопрос {{ i+1 }}
            <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="showEdit(i)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="showDialogDeleteItem(item)"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
        </v-card-title>
        <v-card-text class="text-left">{{ item.question }}</v-card-text>
        </v-card>
            <v-snackbar
                :timeout="3000"
                v-model="snackSuccess"
                fixed
                bottom
                right
                color="success"
                elevation="24"
            >
                {{ snackSuccessText }}
            </v-snackbar>
            <v-snackbar
                :timeout="3000"
                v-model="snackError"
                fixed
                bottom
                right
                color="error"
                elevation="24"
            >
                {{ snackErrorText }}
            </v-snackbar>
      </div>
</template>

<script>
import { getAllQuestions, createQuestion, updateQuestion, removeQuestion } from "../../api/expertiseApi";
export default {
    data() {
        return {
            snackSuccess:false,
            snackSuccessText:'',
            snackError:false,
            snackErrorText:'',
            editedIndex:-1,
            val:'',
            dialog: false,
            questions:[],
            dialogDelete:false,
            deletedItem:null
        }
    },
    async mounted () {
        try{
            let response = await getAllQuestions(this.$route.params.id);
            if(response.ok){
                let json = await response.json();
                this.questions = json.questionList;
                console.log(json)
            }
        }catch(e){
            console.log(e.message);
        }
    },
    methods: {
        async submit() {
            try{
                let response = await createQuestion({ idExpertise : this.$route.params.id , question : this.val});
                if(response.ok){
                    let json = await response.json();
                    console.log(json)
                    this.questions.push(json);
                    this.dialog = false;
                    this.snackSuccess = true;
                    this.snackSuccessText = 'Вопрос успешно добавлен';
                }
            }catch(e){
                this.snackError = true;
                this.snackErrorText = e.message;
            }
        },
        async submitSave(){
            let data = this.questions[this.editedIndex];
            data.question = this.val;
            try {
                let response = await updateQuestion(data);
                if(response.ok){
                    this.snackSuccess = true;
                    this.snackSuccessText = 'Вопрос успешно сохранен';
                    this.editedIndex = -1;
                    this.dialog = false;
                } else {
                    this.snackError = true;
                    this.snackErrorText = 'Не удалось сохранить вопрос';
                }
            } catch (e) {
                this.snackError = true;
                this.snackErrorText = 'Что-то пошло не так';
                console.log(e)
            }
            console.log('s',)
        },
        showEdit(i){
            this.editedIndex = i;
            this.dialog = true;
            this.val = this.questions[i].question;
        },
        openAdd(){
            this.editedIndex = -1;
            this.val = '';
        },
        showDialogDeleteItem(item){
          this.deletedItem = item;
          this.dialogDelete = true;
        },
        async deleteItemConfirm(){
          try{
            let response = await removeQuestion(this.deletedItem.id);
            if(response.ok){
              let i = this.questions.indexOf(this.deletedItem);
              if(i>=0){
                this.questions.splice(i, 1);
                this.snackSuccess = true;
                this.snackSuccessText = 'Вопрос удален';
              } else {
                this.snackError = true;
                this.snackErrorText = 'Не удалось найти вопрос';
              }
            } else {
              this.snackError = true;
              this.snackErrorText = 'Не удалось удалить вопрос';
            }
          } catch(e){
            this.snackError = true;
            this.snackErrorText = 'Что-то пошло не так';
          }finally{
            this.deletedItem = null;
            this.dialogDelete = false;
          }
        }
    },
}
</script>

<style>

</style>