<template>
  <v-container>
    <v-snackbar
      :timeout="3000"
      v-model="snackError"
      fixed
      right
      bottom
      color="error"
      elevation="24"
    >
      {{ textError }}
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="snackSuccess"
      fixed
        right
        bottom
      color="success"
      elevation="24"
    >
      {{textSuccess}}
    </v-snackbar>
    <ExpertiseCard
      v-for="(item, i) in this.$store.getters.getAllExpertises"
      :key="i"
      :expertise="item"
    />
    <v-btn color="pink" dark fixed bottom right fab @click="dialog = !dialog">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>Новая экспертиза</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="align-center d-flex flex-column mt-6" v-if="creatingLoading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div class="mt-2">Создание экспертизы...</div>
          </div>
          <v-form v-model="isValid" ref="newCaseForm" v-if="!creatingLoading" class="mt-6">
            <v-text-field
              label="Номер дела"
              required
              outlined
              dense
              v-model="caseNumber"
              :rules="[(v) => v.length > 0 || 'Обязательное поле']"
            >
            </v-text-field>
            <div class="text-overline mb-4 black--text">Организация</div>
              <v-autocomplete
                  v-model="selectOrganizationID"
                  :items="organizations"
                  item-text="name"
                  item-value="id"
                  label="Организация"
                  outlined
                  dense
                  placeholder="Выберите организацию"
                  @change="getRegionsForOrganization"
                  persistent-placeholder
              ></v-autocomplete>
            <v-autocomplete
                v-model="selectRegionID"
                :items="regions"
                item-text="regionGuide.name"
                item-value="regionGuide.id"
                label="Регион"
                outlined
                dense
                placeholder="Выберите регион"
                persistent-placeholder
            ></v-autocomplete>
            <div class="text-overline mb-4 black--text">Экспертиза</div>
            <v-row>
                <v-col>
                  <v-autocomplete
                      v-model="selectTypeID"
                      :items="types"
                      item-text="name"
                      item-value="id"
                      label="Вид экспертизы"
                      outlined
                      dense
                      hide-details
                      placeholder="Выберите вид экспертизы"
                      persistent-placeholder
                      @change="getSubTypeExpertise"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <v-autocomplete
                        v-model="selectSubTypeID"
                        :items="subTypes"
                        item-text="name"
                        item-value="id"
                        label="Подвид экспертизы"
                        outlined
                        dense
                        hide-details
                        placeholder="Выберите подвид экспертизы"
                        persistent-placeholder
                    ></v-autocomplete>
                </v-col>
              </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!creatingLoading"
            text
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <v-btn text color="primary darken-1" v-if="!creatingLoading" @click="submitNewCase"> Создать </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import * as fetchExpertise from "@/api/expertiseApi";
import ExpertiseCard from "./ExpertiseCard.vue";
import { catalogAPI } from "../../api/catalogsApi";
export default {
  name: "ExpertisesList",
  components: { ExpertiseCard },
  data: () => ({
    selectRegionID:-1,
    selectOrganizationID:-1,
    selectSubTypeID:-1,
    subTypes:[],
    selectTypeID:-1,
    organizations:[],
    types:[],
    regions:[],
    dialog: false,
    isValid: true,
    caseNumber: "",
    snackError: false,
    snackSuccess: false,
    textError:'',
    textSuccess:'',
    creatingLoading:false
  }),
  methods: {
    showError(txt){
      this.snackError = true;
      this.textError = txt;
    },
    showSuccess(txt){
      this.snackSuccess = true;
      this.textSuccess = txt;
    },
    openCreateModal() {
      this.creatingLoading = false;
      this.$refs.newCaseForm.reset();
      this.dialog = true;
    },
    async submitNewCase() {
      this.$refs.newCaseForm.validate();
      if (!this.isValid) return;
      if(this.selectOrganizationID<=0){
        this.showError('Укажите организацию');
        return;
      }
      if(this.selectRegionID <= 0){
        this.showError('Укажите регион');
        return;
      }
      if(this.selectTypeID <= 0){
        this.showError('Укажите вид экспертизы');
        return;
      }
      if(this.selectSubTypeID <=0){
        this.showError('Укажите подвид экспертизы');
        return;
      }
      this.creatingLoading = true;
      let params = {
        "courtCaseNumber": this.caseNumber,
        "idOrgGuide": this.selectOrganizationID,
        "idRegionGuide": this.selectRegionID,
        "idExpTypeGuide": this.selectTypeID,
        "idExpSubtypeGuide": this.selectSubTypeID
      }
      try {
        let response = await fetchExpertise.createExpertise(params);
        if (response.ok) {
          let data = await response.json();
          this.$store.commit("addExpertise", data);
          this.snackSuccess = true;
          this.textSuccess = 'Экспертиза успешно создана'
        } else {
          this.snackError = true;
          this.textError = 'Не удалось создать экспертизу';
        }
        this.dialog = false;
        this.creatingLoading = false;
      } catch {
        this.dialog = false;
        this.creatingLoading = false;
        this.snackError = true;
        this.textError = 'Что-то пошло не так'
      }
    },
    async getOrganizations(){
      try {
        let response = await catalogAPI.getOrganizationCatalog();
        if(response.ok){
          let json = await response.json();
          this.organizations = json.orgGuideList;
        }
      } catch (e) {
        console.warn(e.message);
      }
    },
    async getExpertiseType(){
      try {
        let response = await catalogAPI.getExpertisesTypeCatalog();
        if (response.ok) {
          let json = await response.json();
          this.types = json.expTypeGuideList;
        }
      } catch (e) {
        console.warn(e.message);
      }
    },
    async getRegionsForOrganization(){
      if(!this.selectOrganizationID){
        this.regions = [];
        return;
      }
      this.selectRegionID = -1;
      try{
        let response = await catalogAPI.getOrganizationRegion(this.selectOrganizationID);
        if(response.ok){
          let result = await response.json();
          this.regions = result.orgRegGuide2List
          console.log(result);
        }
      }catch(e){
        console.warn(e.message);
      }
    },
    async getSubTypeExpertise(){
      if(!this.selectTypeID){
        this.subTypes = [];
        return;
      }
      this.selectSubTypeID = -1;
      try {
        let response = await catalogAPI.getAllSubtypesById(this.selectTypeID);
        if(response.ok){
          let json = await response.json();
          this.subTypes = json.expSubtypeGuideList;
          console.log(this.subTypes);
        }
      } catch (e) {
        console.warn(e.message);
      }
    }
  },
  mounted() {
    this.$store.dispatch("fetchExpertises");
    this.getOrganizations();
    this.getExpertiseType();
  },
};
</script>
