<template>
    <div>
        <v-snackbar :timeout="3000" v-model="snackSuccess" fixed bottom right color="success" elevation="24">
            {{ snackSuccessText }}
        </v-snackbar>
        <v-snackbar :timeout="3000" v-model="snackError" fixed bottom right color="error" elevation="24">
            {{ snackErrorText }}
        </v-snackbar>
        <v-container>
            <MaterialCatalog @msg:success="showSucces" @msg:error="showError" class="mt-4" />
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import MaterialCatalog from '../components/Catalogs/MaterialCatalog';
export default {
    components: {
        MaterialCatalog
    },
    data: () => ({
        snackSuccess: false,
        snackError: false,
        snackSuccessText: '',
        snackErrorText: '',
    }),
    methods: {
        showSucces(txt) {
            this.snackSuccess = true;
            this.snackSuccessText = txt;
        },
        showError(txt) {
            this.snackError = true;
            this.snackErrorText = txt;
        },
    }
};
</script>
