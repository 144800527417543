<template>
 <v-container>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Редактирование</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-4">

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialogEdit = false">
                    Отмена
                </v-btn>
                <v-btn color="primary" text @click="submitForm">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-row fluid v-if="showForm">
        <v-col lg="8" offset-lg="2" sm="12">
            <v-card>
                <v-card-title>
                        <v-btn
                        icon
                        color="primary"
                        @click="showForm = false"
                        >
                        <v-icon>
                            mdi-arrow-left
                        </v-icon>
                        </v-btn>
                    {{ editTitle }}</v-card-title>
                <v-card-text>
                    <v-form v-model="isValidFrom" ref="editForm" lazy-validation>
                        <v-text-field outlined dense label="Наименование" v-model="editedItem.name" name="name"
                            >
                        </v-text-field>
                        <v-text-field outlined dense label="Почтовый индекс" v-model="editedItem.postalCode" name="postalCode"
                            >
                        </v-text-field>
                            <v-autocomplete
                            label="Регион"
                            outlined
                            dense
                            name="region"
                            :items="regions"
                            v-model="editedItem.region">
                            </v-autocomplete>
                            <v-text-field outlined dense label="Региональный округ" v-model="editedItem.countyRegion" name="countyRegion"
                                >
                            </v-text-field>
                            <v-text-field outlined dense label="Региональный район" v-model="editedItem.districtRegion" name="districtRegion"
                                >
                            </v-text-field>
                                <v-select
                                :items="localityItems"
                                outlined
                                v-model="editedItem.localityTypeGuideFixed"
                                label="Тип города"
                                name="localityTypeGuideFixed"
                                placeholder="Выберите тип города"
                                dense
                                >
                                </v-select>
                                <v-text-field outlined dense label="Город" v-model="editedItem.localityName" name="localityName"
                                    >
                                </v-text-field>
                                <v-text-field outlined dense label="Городской округ" v-model="editedItem.countyLocality" name="countyLocality"
                                    >
                                </v-text-field>
                                <v-text-field outlined dense label="Городской район" v-model="editedItem.districtLocality" name="districtLocality"
                                    >
                                </v-text-field>
                                    <v-select
                                    :items="typeStreet"
                                    outlined
                                    v-model="editedItem.streetTypeGuideFixed"
                                    label="Тип улицы"
                                    name="streetTypeGuideFixed"
                                    placeholder="Выберите тип улицы"
                                    dense
                                    >
                                    </v-select>
                                    <v-text-field outlined dense label="Улица" v-model="editedItem.streetName" name="streetName"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Дом" v-model="editedItem.house" name="house"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Здание" v-model="editedItem.building" name="building"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Корпус" v-model="editedItem.korpus" name="korpus"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Подъезд" v-model="editedItem.entrance" name="entrance"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Этаж" v-model="editedItem.floor" name="floor"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Квартира" v-model="editedItem.flat" name="flat"
                                        >
                                    </v-text-field>
                                    <v-text-field outlined dense label="Комната" v-model="editedItem.room" name="room"
                                        >
                                    </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    text
                    color="primary"
                    @click="showForm = false"
                    >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Назад
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    v-if="modeNew"
                    class="mt-4"
                    color="primary"
                    @click="submitForm"
                    >
                    Добавить
                    </v-btn>
                    <v-btn
                    v-if="!modeNew"
                    class="mt-4"
                    color="primary"
                    @click="submitForm"
                    >
                    Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

    <v-card v-if="!showForm">
        <v-card-title>Справочник судов</v-card-title>
        <v-card-text>
            <v-btn
            color="primary"
            @click="showAddDialog"
            >
            Добавить
            </v-btn>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th>Адрес</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="courts.length == 0">
                                <td>Справочник пуст</td>
                            </tr>
                            <tr v-for="(item, i) in courts" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.address.address }}</td>
                                <td style="width: 50px;">
                                    <v-btn icon @click="openEditDialog(item, i)">
                                        <v-icon>mdi-file-edit-outline</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import { catalogAPI } from "../api/catalogsApi";
export default {
    data() {
        return {
            editTitle:'',
            showForm:false,
            modeNew:false,
            regions:[],
            defaultCourt:{
                "name": "",
                "postalCode": "",
                "region": "",
                "countyLocality": "",
                "districtRegion":"",
                "districtLocality":"",
                "countyRegion":"",
                "localityTypeGuideFixed": "",
                "localityName": "",
                "streetTypeGuideFixed": "",
                "streetName": "",
                "house": '',
                "building": "",
                "entrance": '',
                "korpus": "",
                "floor": "",
                "flat": "",
                "room": ""
            },
            dialog: false,
            isValidFrom:true,
            editedItem:{
                "name": "",
                "postalCode": "",
                "region": "",
                "countyLocality": "",
                "districtRegion": "",
                "districtLocality": "",
                "countyRegion": "",
                "localityTypeGuideFixed": "",
                "localityName": "",
                "streetTypeGuideFixed": "",
                "streetName": "",
                "house": '',
                "building": "",
                "entrance":'',
                "korpus": "",
                "floor": "",
                "flat": "",
                "room": ""
            },
            localityItems:[],
            typeStreet:[],
            editedIndex:-1,
            courts:[]
        }
    },
    methods: {
        showAddDialog() {
            this.editTitle = 'Добавление суда';
            this.showForm = true;
            this.modeNew = true;
            this.editedIndex = -1;
            Object.assign(this.editedItem,this.defaultCourt);
            if(this.$refs['editForm']){
                this.$refs.editForm.resetValidation();
            }
            this.isValidFrom = true;
            // this.dialog = true;
        },
        async submitForm(){
            let fd = new FormData(this.$refs.editForm.$el);
            if(this.editedIndex==-1){
                try{
                    let response = await catalogAPI.createCourt(Object.fromEntries(fd));
                    if(response.ok){
                        let result = await response.json();
                        this.courts.push(result)
                        this.showForm = false;
                    }
                }catch(e){
                    console.log(e.message);
                }
            } else{
                fd.append('id',this.courts[this.editedIndex].id);
                fd.append('archive',false);
                fd.append('number',1);
                try {
                    let response = await catalogAPI.updateCourt(Object.fromEntries(fd));
                    if (response.ok) {
                        let result = await response.json();
                        console.log(result)
                        let item = this.courts[this.editedIndex];
                        Object.assign(item,result);
                        this.showForm = false;
                    }
                } catch (e) {
                    console.log(e.message);
                }
            }
        },
        openEditDialog(item,i){
            this.editTitle = 'Редактирование суда';
            this.showForm = true;
            this.modeNew = false;
            this.editedIndex = i;
            for(let key in item.address){
                this.editedItem[key] = item.address[key];
            }
            this.editedItem['name'] = item.name;
            // this.$refs.editForm.resetValidation();
            // Object.assign(this.editedItem,item);
            // this.dialog = true;
        }
    },
    async mounted () {
        try{
            //Список регионов
            let regionsResponse = await catalogAPI.getRegions();
            if(regionsResponse.ok){
                let json = await regionsResponse.json();
                this.regions = json.regionRusGuideFixedList;
            }
            let response = await catalogAPI.getAllCourts();
            if(response.ok){
                let result = await response.json();
                this.courts = result.courtGuideList;
                console.log(result)
            }
            let locality = await catalogAPI.getLocality();
            if(locality.ok){
                let arr = await locality.json()
                this.localityItems = arr.localityTypeGuideFixedList
            }
            //Список типов улиц
            let typeStreetsResponse = await catalogAPI.getTypeStreets();
            if(typeStreetsResponse.ok){
                let json = await typeStreetsResponse.json();
                this.typeStreet = json.streetTypeGuideFixedList;
            }
        }catch(e){
            console.log(e.message)
        }
    },
}
</script>

<style>

</style>