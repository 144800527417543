<template>
  <div>
    <!-- Карточка редактирования/добавления -->
    <v-row v-if="showForm">
        <v-col lg="8" offset-lg="2" sm="12">
            <v-card>
                <v-card-title>{{ cardTitleEdit }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field outlined dense label="Фамилия" required v-model="editedItem.lastName"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-text-field outlined dense label="Имя" required v-model="editedItem.firstName"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-text-field outlined dense label="Отчество" required v-model="editedItem.patronymic"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-select label="Суд" outlined item-value="id" item-text="name" dense :items="courtsItems" v-model="editedItem.idCourtGuide"></v-select>
                        <v-checkbox v-if="!modeAdd" hide-details name="requiredCause" v-model="editedItem.archive"
                            :label="`Архивый пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                    text
                    color="primary"
                    @click="showForm = false"
                    >
                    <v-icon left>
                        mdi-arrow-left
                    </v-icon>
                    Назад
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    v-if="modeAdd"
                    color="primary"
                    @click="addNewJudges"
                    >
                    Добавить
                    </v-btn>
                    <v-btn
                    v-else
                    @click="saveChange"
                    color="primary"
                    >
                    Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>


    <v-row v-if="!showForm">
        <v-col lg="8" offset-lg="2" sm="12">
            <v-card>
                <v-card-title>Справочник судей</v-card-title>
                <v-card-text>
                    <v-btn @click="showFormNew"
                    color="primary"
                    >
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    Добавить
                    </v-btn>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                ФИО
                            </th>
                            <th class="text-left">
                                Суд
                            </th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(item,i) in judjes"
                            :key="item.id"
                            >
                            <td>{{ item.lastName  }} {{ item.firstName }} {{ item.patronymic }}</td>
                            <td>{{ getNameCourtById(item.courtGuide.id) }}</td>
                            <td style="width:50px">
                                <v-btn icon @click="openEditDialog(item, i)">
                                    <v-icon>mdi-file-edit-outline</v-icon>
                                </v-btn>
                            </td>
                            </tr>
                            <tr v-if="judjes.length==0" class="text-center">
                                <td colspan="3">Справочник пуст</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

  </div>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
export default {
    data() {
        return {
            cardTitleEdit: 'Добавление нового судьи',
            itemDefault:{
                firstName: '',
                lastName: '',
                patronymic: '',
                idCourtGuide: -1
            },
            editedItem:{
                firstName:'',
                lastName:'',
                patronymic:'',
                idCourtGuide:-1
            },
            modeAdd:true,
            showForm:false,
            courtsItems:[],
            judjes:[],
            indexEdited:-1
        }
    },
    methods: {
        showFormNew() {
            Object.assign(this.editedItem,this.itemDefault);
            this.showForm = true;
            this.modeAdd = true;
            this.cardTitleEdit = 'Добавление записи';
        },
        async addNewJudges(){
            try{
                let response = catalogAPI.addNewJudges(this.editedItem);
                if(response.ok){
                    let data = await response.json();
                    console.log(data);
                    this.judjes.push(data);
                    this.showForm = false;
                    this.$emit('msg:success','Судья добавлен');
                } else {
                    this.showForm = false;
                    let text = await response.text();
                    this.$emit('msg:error', text);
                }
            }catch(e){
                this.showForm = false;
                console.log(e.message);
                this.$emit('msg:error', 'Что-то пошло не так');
            }
        },
        openEditDialog(item,i){
            this.cardTitleEdit = 'Редактирование записи';
            this.modeAdd = false;
            this.showForm = true;
            Object.assign(this.editedItem,item);
            this.indexEdited = i;
        },
        async saveChange(){
            try {
                let response = await catalogAPI.updateJudgeItem(this.editedItem);
                if(response.ok){
                    let data = await response.json();
                    Object.assign(this.judjes[this.indexEdited],data);
                    this.$emit('msg:success', 'Запись обновлена');
                    this.showForm = false;
                } else {
                    let text = await response.text();
                    this.$emit('msg:error', text);
                    this.showForm = false;
                }

            } catch (e) {
                this.showForm = false;
                console.log(e.message);
                this.$emit('msg:error', 'Что-то пошло не так');
            }
        },
        getNameCourtById(id) {
            let find = this.courtsItems.find(item => item.id == id);
            if (find) return find.name;
            else return 'Не удалось найти имя';
        }
    },
    async mounted () {
        try {
            let response = await catalogAPI.getAllCourts();
            if(response.ok){
                let courts = await response.json();
                this.courtsItems = courts.courtGuideList;
            } else {
                this.$emit('msg:error', 'Не удалось получить список судов');
            }
            let catalogJudjes = await catalogAPI.getAllJudes();
            if (catalogJudjes.ok) {
                let judjes = await catalogJudjes.json();
                this.judjes = judjes.judgeGuideList;
            } else {
                this.$emit('msg:error', 'Не удалось получить список судей');
            }

        }catch (error) {
            console.log(error.message);
            this.$emit('msg:error','Что-то пошло не так');
        }
    }
}
</script>

<style>

</style>