import { getAllObjectsNames, getAllInspObject, getDefectsForObject, getAllDefectSurfaces } from "../api/expertiseApi";
export default {
  state: {
    expertises: [],
    objects_inspection: [],
    defects_object: [],
    defects_object_list: [],
    defects_photo_list: [],
    defect_current_obj_id: null,
    defect_surfaces: [],
    selected_defect_fotos: [],
  },
  mutations: {
    setExpertises(state, payload) {
      state.expertises = payload;
    },
    addExpertise(state, payload) {
      state.expertises.unshift(payload);
    },
    setObjectsInspection(state, payload) {
      state.objects_inspection = payload;
    },
    setDefectsObject(state, payload) {
      state.defects_object = payload;
    },
    setDefetsObjectList(state, payload) {
      state.defects_object_list = payload;
    },
    addToDefectsObjectList(state, payload) {
      state.defects_object_list.push(payload);
    },
    updateDefectsObjectListItem(state,defect){
      let findItem = state.defects_object_list.find((item) => item.id == defect.id);
      if(findItem){
        Object.assign(findItem,defect);
      } else {
        console.warn("Не удалось найти дефект для обновления", 'updateDefectsObjectListItem');
      }
    },
    setDefectPhotoList(state, payload) {
      state.defects_photo_list = payload;
    },
    addDefectPhoteToList(state, payload) {
      state.defects_photo_list.push(payload);
    },
    setDefectCurrentID(state, payload) {
      state.defect_current_obj_id = payload;
    },
    setDefectSurfaces(state, payload) {
      state.defect_surfaces = payload;
    },
    addDefectSurfaces(state, payload) {
      state.defect_surfaces.push(payload);
    },
    updateDefectSurface(state,payload){
      let i = payload.editIndex;
      state.defect_surfaces[i].name = payload.name;
      state.defect_surfaces[i].isOpening = payload.isOpening;
    },
    setDefectsSelectedFotos(state, payload) {
      state.selected_defect_fotos = payload;
    },
    removeSelectedFoto(state, index) {
      if (index == 0) state.selected_defect_fotos.shift();
      else state.selected_defect_fotos.pop();
    },
    removeDefectObjectListItem(state, id) {
      let index = state.defects_object_list.findIndex(function (item) {
        return item.id == id;
      });
      if (index) {
        state.defects_object_list.splice(index, 1);
      }
    },
    deletePhotoDefectByID(state, id) {
      let index = state.defects_photo_list.findIndex(function (item) {
        return item.id == id;
      });
      if (index) {
        state.defects_photo_list.splice(index, 1);
      }
    },
    addDefectPhotoToSelectedArray(state,item){
      state.selected_defect_fotos.push(item)
    }
  },
  getters: {
    getAllExpertises(state) {
      return state.expertises;
    },
    getObjectsInspection(state) {
      return state.objects_inspection;
    },
    getDefectsObject(state) {
      return state.defects_object;
    },
    defectsObjectList(state) {
      return state.defects_object_list;
    },
    defectPhotoList(state) {
      return state.defects_photo_list;
    },
    defectObjCurrentID(state) {
      return state.defect_current_obj_id;
    },
    getDefectSurfaces(state) {
      return state.defect_surfaces;
    },
    getDefectSelectedFotos(state) {
      return state.selected_defect_fotos;
    },
  },
  actions: {
    // TODO Вызывать из методов api
    fetchExpertises(context) {
      fetch("https://expertise-develop-bse.amvera.io/api/v1/expertise")
        .then((response) => response.json())
        .then((data) => context.commit("setExpertises", data.expertiseList));
    },
    fetchAllObjectsInspection(context) {
      getAllObjectsNames()
        .then((response) => response.json())
        .then((data) =>{ context.commit("setObjectsInspection", data.inspObjGuideList);console.log(data,'99')});
    },
    fetchDefects(context, idExpertise) {
      getAllInspObject(idExpertise)
        .then((response) => response.json())
        .then(function (data) {
          let result = data.inspObjList.filter(function (item) {
            return item.inspObjGuide.isLinkDefects == true;
          });
          console.log('result',result)
          context.commit("setDefectsObject", result);
        });
    },
    async fetchDefectsObjectList(context, id) {
      let response = await getDefectsForObject(id);
      if (response.ok) {
        let json = await response.json();
        console.log(json.defects,'store');
        context.commit("setDefetsObjectList", json.defects);
        context.commit("setDefectPhotoList", json.photosDefects);
      } else {
        console.log(response.text(),'----')
      }
    },
    addDefectFoto(context, obj) {
      context.commit("addDefectPhoteToList", obj);
    },
    setDefectObjCurrentId(context, id) {
      context.commit("setDefectCurrentID", id);
    },
    addDefectSurfaces(context, obj) {
      context.commit("addDefectSurfaces", obj);
    },
    async fetchDefectSurfaces(context) {
      let response = await getAllDefectSurfaces();
      if (response.ok) {
        let data = await response.json();
        console.log(data)
        context.commit("setDefectSurfaces", data.surfaceGuideList);
      }
    },
    addToDefectsObjectList(context, obj) {
      context.commit("addToDefectsObjectList", obj);
    },
    updateDefectSelectedFotos(context, arr) {
      context.commit("setDefectsSelectedFotos", arr);
    },
    removeDefectInspObj(context, id) {
      context.commit("removeDefectObjectListItem", id);
    },
    removePhotoDefectByID(context, id) {
      context.commit("deletePhotoDefectByID", id);
    },
  },
};
