<template>
  <div>
    <v-btn @click="dialog = true" color="primary">
      <v-icon left>
        mdi-plus
      </v-icon>
      Объект осмотра
    </v-btn>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline mb-2">Выберите объект осмотра</v-card-title>

        <v-card-text>
          <v-select
            v-model="selectedObject"
            :items="objects"
            item-text="name"
            item-value="id"
            label="Объекты осмотра"
            outlined
            dense

          />

          <v-select
            v-model="selectedNumber"
            :items="numberItems"
            label="Номер"
            outlined
            dense
          />

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false" text>
            Закрыть
          </v-btn>
          <v-btn color="primary" @click="addObject">
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    objects: {
      type: Array,
      required: true, // Передаваемый список объектов обязателен
    },
  },
  data() {
    return {
      dialog: false, // Управляет состоянием диалога (открыт/закрыт)
      selectedObject: null, // Выбранный объект
      numberItems: [{text: 'Без номера', value: 0},
                    {text: '1', value: 1},
                    {text: '2', value: 2},
                    {text: '3', value: 3},
                    {text: '4', value: 4},
                    {text: '5', value: 5},
                    {text: '6', value: 6},
                    {text: '7', value: 7},
                    {text: '8', value: 8},
                    {text: '9', value: 9},
                    {text: '10', value: 10},
                  ],
      selectedNumber: 0
    };
  },
  methods: {
    addObject() {
      // Отправляем событие наверх с выбранным объектом
      console.log(this.selectedObject)
      this.$emit('add-object', this.selectedObject, this.selectedNumber);
      this.dialog = false; // Закрываем диалог
    }
  },
};
</script>