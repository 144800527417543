<template>
    <div>
        <v-card v-if="!showForm">
            <v-card-title>
                Справочник поверхностей
            </v-card-title>
            <v-card-text>
                <v-btn
                color="primary"
                @click="showNewForm()"
                >
                Добавить
                </v-btn>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Наименование</th>
                                        <th>Является проемом</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item,i) in surfaces"
                                    :key="item.id"
                                    >
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.isOpening?'Да':'Нет' }}</td>
                                    <td style="width: 50px;">
                                        <v-btn icon @click="openEditDialog(item, i)">
                                            <v-icon>mdi-file-edit-outline</v-icon>
                                        </v-btn>
                                    </td>
                                    </tr>
                                    <tr v-if="surfaces.length == 0">
                                        <td class="text-center">Справочник пуст</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-if="showForm">
            <v-card-title>{{ titleForm }}</v-card-title>
            <v-card-text>
                <v-form v-model="isValidSurfaceForm" ref="newSurfaceForm">
                    <v-text-field
                    outlined
                    dense
                    label="Наименование поверхности"
                    required
                    v-model="surfaceName"
                    :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                    >
                    </v-text-field>
                    <v-checkbox hide-details name="isOpening" v-model="isOpening"
                        :label="`Является проемом`"></v-checkbox>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                text
                color="primary"
                @click="showForm = false"
                >
                <v-icon left>
                    mdi-arrow-left
                </v-icon>
                Назад
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                v-if="modeNew"
                class="mt-4"
                color="primary"
                @click="submitNewSurface"
                >
                Добавить
                </v-btn>
                <v-btn
                v-if="!modeNew"
                class="mt-4"
                color="primary"
                @click="submitEditSurface"
                >
                Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>

</template>

<script>
import { addNewDefectSurface, updateSurface } from '../../api/expertiseApi'
export default {
    components:{
    },
    data:()=>({
        titleForm:'Добавление поверхности',
        showForm:false,
        surfaceName:'',
        modeNew:false,
        isOpening:false,
        isValidSurfaceForm:true,
        editedIndex:null,
        editedID:null
    }),
    methods:{
        showNewForm(){
            this.showForm = true;
            this.modeNew = true;
            this.surfaceName = '';
            this.isOpening = false;
            this.titleForm = 'Добавление поверхности';
        },
        openEditDialog(item,i){
            this.editedID = item.id;
            this.showForm = true;
            this.modeNew = false;
            this.titleForm = 'Редактирование поверхности';
            this.isOpening = item.isOpening;
            this.surfaceName = item.name;
            this.editedIndex = i;
        },
        async submitEditSurface(){
            let json = {id:this.editedID, name: this.surfaceName, archive: false, number: 1, isOpening: this.isOpening };
            try{
                let response = await updateSurface(json);
                if(response.ok){
                    this.surfaces[this.editedIndex].name = this.surfaceName;
                    this.surfaces[this.editedIndex].isOpening = this.isOpening;
                    this.$store.commit('updateDefectSurface',{ editIndex : this.editedIndex,name:this.surfaceName, isOpening :this.isOpening});
                    this.showForm = false;
                }
            }catch(e){
                console.log(e.message);
            }
        },
        async submitNewSurface() {
            this.$refs.newSurfaceForm.validate();
            if (!this.isValidSurfaceForm) return;
            try {
                // let formData = new FormData();
                // formData.append('name',this.surfaceName)
                let json = {name:this.surfaceName, archive : false,number:-1, isOpening : this.isOpening };
                let response = await addNewDefectSurface(JSON.stringify(json));
                if (response.ok) {
                    let data = await response.json();
                    this.$store.commit("addDefectSurfaces", data);
                    this.surfaceName = '';
                    this.$refs.newSurfaceForm.resetValidation()
                    this.showForm = false;
                }
            } catch(e) {
                console.log('error',e)
            }
        },
    },
    mounted(){
        this.$store.dispatch('fetchDefectSurfaces');
    },
    computed:{
        surfaces(){
            return this.$store.getters.getDefectSurfaces
        }
    }

}
</script>