<template>
    <div>
        <v-snackbar
            :timeout="3000"
            v-model="snackSuccess"
            fixed
            bottom
            right
            color="success"
            elevation="24"
        >
            {{ snackSuccessText }}
        </v-snackbar>
        <v-snackbar
            :timeout="3000"
            v-model="snackError"
            fixed
            bottom
            right
            color="error"
            elevation="24"
        >
            {{ snackErrorText }}
        </v-snackbar>
        <v-container>
            <v-row>
                <v-col cols="6">
                    <ExpertisesTypeCatalog @upd:type="typeCatalogUpdated" @msg:success="showSucces" @msg:error="showError" class="mt-4" />
                </v-col>
                <v-col cols="6" v-show="false">
                    <ExpertisesSubTypeCatalog :expertiseTypes="expertiseTypes" @msg:success="showSucces" @msg:error="showError" class="mt-4" />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <!-- <ExpertiseTypesCatalog :expertiseTypes="expertiseTypes" @msg:success="showSucces" @msg:error="showError" class="mt-4" /> -->
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import ExpertisesTypeCatalog from '../components/Catalogs/ExpertisesTypeCatalog';
import ExpertisesSubTypeCatalog from '../components/Catalogs/ExpertisesSubTypeCatalog';
// import ExpertiseTypesCatalog from '../components/Catalogs/ExpertiseTypesCatalog';
export default {
    name: "CatalogSurfaces",
    components: {
        ExpertisesTypeCatalog,
        ExpertisesSubTypeCatalog,
        // ExpertiseTypesCatalog
    },
    data: ()=>({
        snackSuccess:false,
        snackError: false,
        snackSuccessText: '',
        snackErrorText:'',
        expertiseTypes:[]
    }),
    methods:{
        showSucces(txt){
            this.snackSuccess = true;
            this.snackSuccessText = txt;
        },
        showError(txt){
            this.snackError = true;
            this.snackErrorText = txt;
        },
        typeCatalogUpdated(arr){
            console.log(arr,'upd')
            this.expertiseTypes = arr;
        }
    }
};
</script>
